import React, { Component } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import API from './API'
import ReactPlayer from 'react-player'
import {Dropdown, Card, Row, Col, NavItem, CollectionItem, Input, Icon, Button, Badge} from 'react-materialize'
import moment from 'moment'
import aws_exports from './aws-exports';
import logo from './vm_icon.png';
import './App.css';

Amplify.configure(aws_exports);
Storage.configure({ level: 'private' });

export default class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //hierarchyLevel: 1,
     // hierarchyList: [],
      admin: false,
      selectedView: 'My Videos',
      showManager: false,
      videoIndex: 0,
      unloadPlayer: false,
      videoID: null,
      volume: 1,
      count: 0,
      hiddenPaused: false,
      seconds: 0,
      duration: 0,
      type: null,
      agentVideos: [],
    };
  }

  async componentDidMount(){
    const { type} = this.props
   // console.log(this.props)
 
    document.addEventListener("visibilitychange", (event) => {
      this.handleVisibleChange(document.visibilityState)
    });
    this.setState({type})
    this.list()
   
  }
  static getDerivedStateFromProps(props, state) {
 
    const { type } = state
  
    if(type){
      return null
    }else{
      const { videoGroups, currentUser, admin } = props
      const { groups } = currentUser
      if(admin){
        if(videoGroups.length > 0){
          
          return { ...state, type: videoGroups[0]}
        }
      }else{
        if(groups){
          const availibleGroups = videoGroups.filter(element => groups.includes(element));
          if(availibleGroups.length > 0){
         
            return { ...state, type: availibleGroups[0]}
          }
        }
      }
    
      return null
    }
   

    // Return null if the state hasn't changed

  }
  /*listHierarchy = async (id) => {
    const hierarchyList = await API.get(process.env.REACT_APP_API , `/hierarchy`);
    this.setState({hierarchyList})
  }*/
  saveAgent = async (body) => {
    const { userID } = this.props.currentUser
    const response = await API.post(process.env.REACT_APP_API , '/users', {body}, userID);

   
    if('success' in response){
      const { data } = response
      if ('userID' in data){
        return this.setState({currentUser: data})
      }
    }
    ///handle error

  }
  saveVideoRecord = async (body) => {
    const { userID } = this.props.currentUser
    //console.log(body)
    const response = await API.post(process.env.REACT_APP_API , '/track/videos', {body}, userID);

    this.saveAgentVideoList(response)
  }
  //need to check against local storage to see whats newer       localStorage.getItem("lastname");
  getViewedVideo = async (id) => {
    const { userID } = this.props.currentUser
    const agentVideo = await API.get(process.env.REACT_APP_API , `/videos/object/${id}`, userID);
    //console.log(agentVideo)
    const { percent } = agentVideo
    if(percent >= 1){
       this.setState({...agentVideo, completed: true})
       return
    }
    const localVideo = localStorage.getItem('video')
    if(localVideo){
      const video = JSON.parse(localVideo)
      const { videoID, seconds } = agentVideo
      const { videoID: localVideoID, seconds: localSeconds } = video

      if(localVideoID === videoID){
          if(localSeconds > seconds){
            this.setState({...localVideo, completed: false})
            return
          }
      }
    }
    this.setState({...agentVideo, completed: false})
  }
  list = async () => {
    const { userID } = this.props.currentUser
    //console.log(this.props)
    const agentVideos = await API.get(process.env.REACT_APP_API , '/videos', userID );
    this.setState({agentVideos})
  }
  saveFromLocal = () => {
    const video = localStorage.getItem("video");
    if(video){
      this.saveVideoRecord(JSON.parse(video))
    }
  }
  completeVideo = () => {
   // console.log('completeVideo')
    const { seconds, duration, percent, videoID, name, type } = this.state
    const modified = moment().format()
    const payload = { seconds:duration, lastSeconds: seconds, lastPercent:percent, duration, percent:1, videoID: parseInt(videoID), name, type, modified, count: -1 }
    this.setState({completed: true})
   // localStorage.setItem('video', JSON.stringify({...payload, completed: true}));
   localStorage.removeItem('video')
    return this.saveVideoRecord(payload)

  }
  saveTime = (count) => {

     const { seconds, duration, percent, videoID, name, type, completed } = this.state
     const modified = moment().format()
     const payload = { seconds, duration, percent, videoID: parseInt(videoID), name, type, modified, count: count ? count : 0 }


     if(count){
        const apiStore = count % 100
        const localStore = count % 5
        if(count === 3){
          this.saveVideoRecord(payload)
          return 
        }
        if(localStore === 0){
          localStorage.setItem('video', JSON.stringify({...payload, completed}));
        }
        if(apiStore === 0){
          this.saveVideoRecord(payload)
        }
     }else{
      this.saveVideoRecord(payload)
     }
  }
  saveAgentVideoList = (updateVideoRecord) => {
    //console.log(updateVideoRecord)
    const { agentVideos} = this.state
    const { Attributes } = updateVideoRecord
    const updatedVideo = {count: parseInt(Attributes.count.N), 
    duration: parseFloat(Attributes.duration.N), 
    modified: Attributes.modified.S, 
    name: Attributes.name.S,
    percent: parseFloat(Attributes.percent.N),
    seconds: parseInt(Attributes.seconds.N),
    type: Attributes.type.S,
    userID: Attributes.userID.S,
    videoID: Attributes.videoID.S}

    const otherVideos = agentVideos.filter((video) => {
      return updatedVideo.videoID !== video.videoID
    })

    otherVideos.push(updatedVideo)

    this.setState({agentVideos: otherVideos})

  }
  selectVideo = (index, id) => {
    const { videos } = this.props
    const { type, videoID: currentVideoID, seconds, agentVideos, completed } = this.state
    const video = videos[type][index]
    const { videoID } = video
    const startedVideo = this.getStartedVideo(videoID)
      if(!completed){
          this.saveFromLocal()
      }
          if(currentVideoID === id) {
          }else if(startedVideo){
            this.getViewedVideo(videoID)
            this.setState({ videoIndex: index, ...startedVideo });
          }else{
            const newVideo = {...video, videoID: videoID.toString(), percent: 0, seconds: 0, duration: 0, completed: false}
            this.setState({ videoIndex: index, ...newVideo, videoID: videoID.toString()});
          }
      
  }

  handleVisibleChange = (status) => {

    if(status === 'hidden'){
      this.saveFromLocal()
      if(this.player){
        this.player.getInternalPlayer().pause()
      }
    //  this.setState({videoID: null})
    }
  }
  handlePlayerLoaded = (event) => {
    const { name, seconds } = this.state

    this.player.seekTo(seconds)
  }
  handlePlayerEnd = (event) => {
   // console.log('handlePlayerEnd')
    const { completed } = this.state
    //console.log(completed)
  //  localStorage.removeItem('video')
    if(!completed){
      this.completeVideo()
    }
  }

  handlePlayerPause = () => {
    const { completed } = this.state
    if(!completed){
      this.saveFromLocal()
    }
  }

  handlePlayerPlay = (event) => {
     const duration = this.player.getDuration()
     this.setState({ duration});
  }

  handleVolume = (event) => {
    this.setState({
      volume: parseFloat(event.target.value),
    })
  }

  handleTypeSelection = (event) => {
    this.setState({type: event.target.text})
  }
  //need to add local storage
  handleTimeUpdate = (event) => {
    //console.log('handleTimeUpdate')
    const { seconds, count, duration, completed } = this.state

    if(completed){
      this.setState({ seconds: duration, percent:1 , count: -1 })
      return
    }
    
    const { playedSeconds } = event
    let percent = 0
    if(playedSeconds > 0){
      percent = playedSeconds / duration
    }

   const secondDiff = playedSeconds - seconds
   const newCount = seconds === 0 ? 0 : count + 1

    if(secondDiff >= 0 ){
      if(secondDiff > 2){
        this.player.seekTo(seconds)
      }else{
        this.setState({ seconds: playedSeconds, percent , count: newCount }, () => {
          
          if(playedSeconds > 0){
           // console.log(playedSeconds)
            this.saveTime(newCount)
          }
        })
      }
    }

  }

  makeVideoTypeDropdown = () => {
    const { videos, currentUser, admin } = this.props
    let {videoGroups } = this.props
    const { groups: userGroups } = currentUser
    const { type } = this.props;
    let groups = []
    if(!videos){
      return null
    }

    if(!admin){
      if(!userGroups){
        groups = videoGroups[0] //temp fix need to make so it can be set by admin
    
      }else{
        groups = userGroups
      }

      videoGroups = videoGroups.filter(element => groups.includes(element));
   
    }

    const navOptions = videoGroups.map(type => {
          return <NavItem href='#' onClick={this.handleTypeSelection} key={type} >{type}</NavItem>
    })

    return (<Dropdown
    trigger={
      <Button>{type}<Icon right>arrow_drop_down</Icon></Button>
    }>
   {navOptions}
  </Dropdown>)

  }
  getStartedVideo = (id) => {
    const { agentVideos } = this.state

    const startedVideo = agentVideos.filter(video => {
          return id.toString() === video.videoID
    })

    if(startedVideo.length > 0){
      return startedVideo[0]
    }
    return null
  }
  makeVideoMenu = (type) => {
  
    if(!type){
      return null
    }
    const { videos } = this.props
    const { videoID, percent: currentPercent } = this.state;

 
    if(!videos){
      return null
    }

    if( !(type in videos)){
      return []
    }

    return videos[type].map((choice, index) => {

      const { required, name, videoID: choiceID } = choice
      const startedVideo = this.getStartedVideo(choiceID.toString())

      let completion = 0
      if(startedVideo){
        if(startedVideo.videoID === videoID){
          completion = currentPercent
        }else{
          const { percent, seconds } = startedVideo
          if(percent === 0 && seconds > 0){
            completion = .01
          }else{
            completion = percent 
          }
        }
      }

      console.log(Math.round(completion))
      
  
     return (<CollectionItem 
      key={choiceID}
      href={`#/home`} 
      active={videoID === choiceID.toString()} 
      onClick={() => this.selectVideo(index, choiceID)} 
      > 
      {name}
      <Badge 
      style={{color: 'white'}} 
      className={`${completion === 0 ? 'blue' : completion < 1  ? 'red' : 'green'}`}
      >
      {completion > 0 ? `${Math.round(completion * 100)}%` : 'Not Started' } </Badge>
      </CollectionItem>)
    })
  }
  makeViewDropdown = () => {
      const { selectedView, admin, currentUser } = this.state
      if(!currentUser){
        return null
      }
      const { isManager } = currentUser
  
    return (<Dropdown
    trigger={
      <Button>{selectedView}<Icon right>arrow_drop_down</Icon></Button>
    }>
    <NavItem href='#' onClick={() => this.setState({selectedView: 'My Videos'})} key={'videos'} > My Videos</NavItem>
    {isManager || admin ? <NavItem href='#' onClick={() => this.setState({selectedView: 'My Agents'})} key={'manager'}>My Agents</NavItem> : null}
    {admin ? <NavItem href='#' onClick={() => this.setState({selectedView: 'Manage Videos'})} key={'Manage Videos'} >Manage Videos</NavItem> : null}
    {admin ? <NavItem href='#' onClick={() => this.setState({selectedView: 'Account'})} key={'Account'} >Account</NavItem> : null}
  </Dropdown>)

  }
  ref = player => {
    this.player = player
    //this.vplayer = this.player.getInternalPlayer()
  }
  /*renderVideo = () => {
    const { type, videoID } = this.state;
    const borderWidth = videoID ? 0 :  'medium'
    return (<div className="row" style={{marginLeft: 20}}>
        <div className="col s5">
          <h5>
            Videos
          </h5>
             {this.makeVideoTypeDropdown()}

             <div className="collection">
                {this.makeVideoMenu(type)}
             </div>
        </div>
        <div className="col s7 center-align">
        <div style={{ margin: 100, width:640, height:400, borderStyle: 'solid', borderWidth }}> {videoID ?  
          <ReactPlayer
          ref={this.ref}
          url={`https://vimeo.com/${videoID}`}
          playing={false}
          onPause={this.handlePlayerPause}
          onPlay={this.handlePlayerPlay}
          onProgress={this.handleTimeUpdate}
          onEnded={this.handlePlayerEnd}
          onReady={this.handlePlayerLoaded}
          config={{
            vimeo: {
              playerVars: { 
                api: true,
                autopause: true
              }
            }
          }}
        /> :  <img src={logo} className="App-logo" style={{marginTop: 55}} alt="logo" /> } </div> 
        </div>
      </div>)
    }*/

  render() {
    const { height } = this.props
    const { type, videoID } = this.state;
    const borderWidth = videoID ? 0 :  'medium'
    return (
      
      <Row style={{marginLeft: 20}}>
      
        <Col s={5}>
          <h5>
            Videos
          </h5>
             {this.makeVideoTypeDropdown()}

             <div className="collection">
                {this.makeVideoMenu(type)}
             </div>
        </Col>
        <Col s={7} className="center-align">
        <Card style={{backgroundColor: '#1F2833', margin: 50, height: (height * .50)}} >
            {videoID ?  
              <ReactPlayer
              ref={this.ref}
              width='100%'
              height={(height * .50) - 100}
              url={`https://vimeo.com/${videoID}`}
              playing={false}
              onPause={this.handlePlayerPause}
              onPlay={this.handlePlayerPlay}
              onProgress={this.handleTimeUpdate}
              onEnded={this.handlePlayerEnd}
              onReady={this.handlePlayerLoaded}
              config={{
                vimeo: {
                  playerVars: { 
                    api: true,
                    autopause: true
                  }
                }
              }}
            /> :  
 
              <img className="center-align" src={logo} style={{width:125, marginTop: 45}} alt="logo" /> 

      } 
        </Card>
            </Col>
      </Row>
      )
  }
  
}

