// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "sonicviewer-20190105105225--hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d33604le1y94ai.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:d3b0e259-9282-43e7-a9b6-495d171ca378",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0PSYaowzB",
    "aws_user_pools_web_client_id": "6u4s1rr00ei380ejnmlpek6h54",
    "aws_mobile_analytics_app_id": "fbd611e3bf674deca87338d0c72be438",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "sonicviewerd58b52f1778f4ef98f56fbf3a694295a-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "agent_videos-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "agent_hierarchy-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "viewer_users-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "company_agents-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "user_accounts-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apicef032be",
            "endpoint": "https://w1ts4xf38f.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
