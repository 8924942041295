import React, { Component, Fragment } from 'react';
import API from './API'
import { Input, Row, Col, Button, Section, Card, Divider, ProgressBar} from 'react-materialize'
import moment from 'moment'
import validator from 'validator'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import logo from './logo.png';
import LiveButton from './LiveButton';
import './App.css';

function makeid() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 9; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}


class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifiedEmail: false,
      admin: false,
      loading: false,
      success: null,
      error: null,
      emailValid: false,
      managerEmailValid: true,
      requireEmailVerification: false,
      email: '', 
      firstName: '', 
      lastName: '', 
      managerEmail: '', 
      tempPassword: '',
      selectedCurrentEmail: false,
      editingPassword: true,
      groups:[],
      newUser: true
    };
  }
  async componentDidMount(){
    const { user, currentUser, manager } = this.props
    const { email } = currentUser

    const { email: managerEmail } = manager

    console.log(user)

    if(user){
      return this.setState({...user, managerEmail, verifiedEmail: true, newUser: false, emailValid: true, managerEmailValid: true})
    }

    this.setState({managerEmail, selectedCurrentEmail: true, newUser: true})
  }
  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
 
    return state

  }
  refreshForm = () => {
    this.setState({
      admin: false,
      requireEmailVerification: false,
      email: '', 
      firstName: '', 
      lastName: '', 
      managerEmail: '', 
      tempPassword: '',
      editingPassword: true
    })
  }
  clearMessages = () => {
    this.setState({
      loading: false,
      success: null,
      error: null,
    })
  }
  handleSubmit = async () => {
    
    const { currentUser, user, refreshAgents, manager } = this.props
    const { newUser, emailValid, managerEmailValid, userID: agentID , email, firstName, lastName, managerEmail, admin, requireEmailVerification, tempPassword, isManager, groups } = this.state

    console.log(firstName)
    console.log(lastName)
    console.log(managerEmail)
    console.log(email)
    console.log(emailValid)
    console.log(groups)


    if(firstName === undefined || firstName === null || firstName === '' || lastName === undefined || lastName === null || lastName === ''|| managerEmail === undefined || managerEmail === null || managerEmail === '' || email === undefined || email === null || email === ''){
        return this.setState({error: 'Please complete all fields!', loading: false})
    }
   
    if(!emailValid){
      return this.setState({error: 'Please complete all fields!', loading: false})
    }

    if(!managerEmailValid){
      return this.setState({error: 'Please complete all fields!', loading: false})
    }



    const { userID, corp, network } = currentUser
    const modified = moment().format()
    const body =  { agentID, email: email.toLowerCase(), firstName, lastName, managerEmail: managerEmail.toLowerCase(), admin, requireEmailVerification, tempPassword, corp, network, modified  }
    if(groups.length > 0){
      body.groups = groups
    }

    this.setState({loading: true})
    if(user){
      
      body.isManager = isManager
      this.setState({loading: true})
      const response = await API.put(process.env.REACT_APP_API , `/users/manager`, {body}, userID);
      console.log(response)
      if('error' in response){
        const { error } = response
        return this.setState({error:error.message, loading: false})
      }

      const agent = await API.get(process.env.REACT_APP_API , `/users/${userID}`, userID);
      console.log(agent)
      if('success' in response){
        refreshAgents(manager)
        this.setState({loading: false, ...agent, success: 'Succesfully Updated User!'})
      }else if('error' in response){
        const { error } = response
        this.setState({error:error.message, loading: false})
      }
    }else{
      this.setState({loading: true})
      const response = await API.put(process.env.REACT_APP_API , `/users`, {body}, userID);
      console.log(response)
      if('success' in response){
          this.refreshForm()
          /*
          notify of change to prior user
          admin:false
          email:"jasonhartin@gmail.com"
          firstName:"Jason"
          lastName:"Hartin"
          managerEmail:"jason@sonicwebdev.com"
          modified:"2019-01-28T12:38:08-05:00"
          network:"sonic"
          */
          return this.setState({loading: false, success: 'User Invited!'})
      }else if('error' in response){
        const { error } = response
        
        this.setState({error:error.message, loading: false})
      }
    }
  }
  
  handleInputUpdate = (field, value) => {

    const newValue = value.trim()
  
    let { selectedCurrentEmail, isManager, managerEmail, requireEmailVerification, emailValid, managerEmailValid, groups  } = this.state


    if(field === 'currentManagerEmail'){
      this.setState({managerEmail: newValue, selectedCurrentEmail: !selectedCurrentEmail,
        loading: false,
        success: null,
        error: null,
      })
      return
    } 
    else if (field === 'groups') {
      let updatedGroups = [...groups]
      if(groups.includes(value)){
        updatedGroups = groups.filter(item => {
            return item !== value
        })
      }else{
        updatedGroups.push(value)
      }
      this.setState({
        groups: updatedGroups,
        loading: false,
        success: null,
        error: null,
      })
      return
    }
  
    else if (field === 'email'){

      if(!(validator.isEmail(newValue))){
        emailValid = false
      }else{
        emailValid = true
      }
      this.setState({email: newValue, managerEmail, emailValid, managerEmailValid,
        verifiedEmail: false,
        loading: false,
        success: null,
        error: null,
      })
      return
    }
    else if (field === 'managerEmail'){
      selectedCurrentEmail = false
      if(!(validator.isEmail(newValue))){
        managerEmailValid = false
      }else{
        managerEmailValid = true
      }
    
    }else if (field === 'isManager'){ 
      if(managerEmail === '' || managerEmail === null || managerEmail === undefined){
        const { manager } = this.props
        const { email: currentManagerEmail } = manager
        this.setState({isManager: !isManager, managerEmail: currentManagerEmail,
          loading: false,
          success: null,
          error: null,
        })
      }else{
        this.setState({isManager: !isManager, managerEmail, emailValid, managerEmailValid,
          loading: false,
          success: null,
          error: null,
        })
      }
      return
    }

    this.setState({[field]: newValue, selectedCurrentEmail, emailValid, managerEmailValid,
      loading: false,
      success: null,
      error: null,
    })
  }
  makeGroups = () => {
      const { groups } = this.props
      const { groups: currentGroups } = this.state

      console.log(groups)
      console.log(currentGroups)

      return groups.map((item) => {

        return <Input style={{paddingBottom: 10}}  key={item} value={item}  checked={currentGroups.includes(item)} onChange={(event) => this.handleInputUpdate('groups', event.target.value)} name={item} type='checkbox'  label={item}  />

    })
  }
  render() {
  
    const { newUser, email, firstName, lastName, requireEmailVerification, tempPassword, selectedCurrentEmail, isManager, editingPassword, loading, success, error, firstNameError, lastNameError, emailValid, managerEmailValid, verifiedEmail } = this.state
    const { manager, user, currentUser, groups } = this.props

    const { email: managerEmail } = manager
    const { admin } = currentUser

    console.log(manager)

    return (
      <Row>
      <Col m={1} />
      <Col m={10} >
      <Card style={{marginBottom: 200, marginRight: 20, maxWidth: 650, backgroundColor: '#1F2833'}}>
      <Card style={{padding: 20}}>
      <h5>{ newUser ? 'Invite New User' : 'Edit User'}</h5>
      <Row>
        <Col m={1} />
        <Col m={10} >
          <Row>
            {user  ? <Input name='group1' type='checkbox' checked={isManager} value='manager' label='User Is A Manager' onChange={(event) => this.handleInputUpdate('isManager', event.target.value)} /> : null }
          </Row>
          <Section>
          { !newUser ?  <Fragment><Row>
              <h6>GROUPS</h6>
          </Row>
          <Row>
            {this.makeGroups()}
          </Row></Fragment> : null }
          </Section>
              <Row >
              <Input m={12} value={email} disabled={!newUser} error={!emailValid ? ' ' : null} type="email" onChange={(event) => this.handleInputUpdate('email', event.target.value)} label={newUser ? "User Email" : null }/>
              <Input s={6} className="active" disabled={!newUser} error={firstNameError ? ' ' : null} value={firstName} onChange={(event) => this.handleInputUpdate('firstName', event.target.value)} label={newUser ? "First Name" : null }/>
              <Input s={6}  value={lastName} disabled={!newUser} error={lastNameError ? ' ' : null} onChange={(event) => this.handleInputUpdate('lastName', event.target.value)}  label={newUser ? "Last Name" : null }/>
              <Input s={12} disabled={selectedCurrentEmail} error={!managerEmailValid ? ' ' : null} type="email" label={"Manager Email"} onChange={(event) => this.handleInputUpdate('managerEmail', event.target.value)} />
              {verifiedEmail ? <p style={{marginLeft:10}}>{`Current manager: ${managerEmail}`}</p> : <Input name='group2' type='checkbox' checked={selectedCurrentEmail} value='managerEmail' label={`Use ${managerEmail} as manager email`} onChange={(event) => this.handleInputUpdate('currentManagerEmail', managerEmail)} />  }
          </Row>
          <Row>


          </Row>
             <Row>
              <Col s={3} />
                  <Col s={6} >
                    <LiveButton 
                    title={'Submit'}
                    onClick={this.handleSubmit}
                    success={success}
                    error={error}
                    loading={loading}
                    />
                  </Col>
              <Col s={3} />
            </Row>
            </Col>
            <Col m={1} />
      </Row>   
      </Card>
      </Card>
      </Col>
      </Row>
    );
  }
}
//{isNew ? 'Submit' : 'Save'}
export default User;

