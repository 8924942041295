import React from "react";
import { Auth } from 'aws-amplify';


export default class App extends React.Component {
  componentWillMount() {
    const { currentUser } = this.props
    const { admin, networkDomain } = currentUser
    Auth.signOut({ global: true })
    .then(data => {
        console.log(data)
        //this.props.history.replace("/")
        window.location = admin ? "https://www.thevideomanager.com/users" : `https://www.${networkDomain}/users`
       // window.location.reload();
    })
    .catch(err => console.log(err));
  }

  render() {
    return null
  }
}
