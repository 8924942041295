import React, { Component } from 'react';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { withRouter} from "react-router-dom"
import { ProgressBar } from 'react-materialize'
import API from './API'
import Header from './Header'
import Home from './Home'
import Footer from './Footer'
import Logout from './Logout'
import aws_exports from './aws-exports';
import './App.css';

//Amplify.configure(aws_exports);
Amplify.configure({
  ...aws_exports,
  Analytics: {
    disabled: true
  }
});
Storage.configure({ level: 'private' });

class MainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showUserTypeVerify: true,
   //  selectedView: 'Intro',
      backgroundColor: '#45A29E',
      height:0,
      currentUser: {account:{free: true}},
      videos:[], 
      videoGroups:[],
      type: null
    };
  }

  async componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    Auth.currentUserInfo()
    .then(data => {
        console.log(data)
        const { id: userID, attributes } = data
   
        const { 
            email, 
            ['custom:admin']: admin, 
            ['custom:network']: network, 
            ['custom:corp']: corp, 
            given_name: firstName, 
            family_name: lastName,
            ['custom:accountType']:accountType,
            ['custom:planName']:planName,
            ['custom:customer']:customer
       } = attributes
       
        let adminLevel = true 
        if(admin){
          adminLevel = parseInt(admin) > 0
        }

        const currentUser = {userID, email, network, corp, firstName, lastName, planName, customer, accountType }
      

        this.setState({admin: adminLevel, currentUser, selectedView: adminLevel ? 'Intro' : 'My Videos' })
        this.saveAgent(currentUser)
        this.refreshVideos(network)
 
        /*return Storage.get(`videos/collections/${network}/videoCollection.json`)
        .then (collectionURL => {

                return fetch(collectionURL)
                .then((result) => {
                  if(result.status === 200){
                    return result.json()
                  }else{
                    return Promise.reject(new Error('Bad Request'))
                  }
                })
                .then((videos) => {
                  console.log(videos)
                  const videoGroups = Object.keys(videos)
                  this.setState({loaded: true, videos, videoGroups, type: videoGroups.length > 0 ? videoGroups[0] : null})
                })
          })*/
      })
      .catch((err) => {
         console.log(err)
         this.setState({loaded: true})
         //should redrirec to login or put a toast up
      })
  }

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}
updateWindowDimensions = () => {
  // document.removeEventListener('keydown', this.onKeydown);

  const windowHeight = window.innerHeight;

  if(this.footer){
  
      const footerHeight = this.footer.divElement.clientHeight
   
      const height = windowHeight - (footerHeight ? footerHeight : 0)
      //const height = footerTopOffset ? footerTopOffset : windowHeight
   
      this.setState({ height: height < 625 ? 625 : height });
  }else{
    this.setState({ height:  windowHeight });
  }

  }

  refreshVideos = async(network) => {

     Storage.get(`videos/collections/${network}/videoCollection.json`)
    .then (collectionURL => {

            return fetch(collectionURL)
            .then((result) => {
              if(result.status === 200){
                return result.json()
              }else{
                return Promise.reject(new Error('Bad Request'))
              }
            })
            .then((videos) => {
              console.log(videos)
              const videoGroups = Object.keys(videos)
              this.setState({loaded: true, videos, videoGroups, type: videoGroups.length > 0 ? videoGroups[0] : null})
            })
      })
  .catch((err) => {
     console.log(err)
     this.setState({loaded: true})
     //should redrirec to login or put a toast up
  })

  }

  saveAgent = async (body) => {



    const response = await API.post(process.env.REACT_APP_API , '/users', {body});
    console.log(response)
    if('success' in response){
      const { data } = response
      if ('userID' in data){
        this.setState({currentUser: {...body, ...data}})
        
      }
    }
  }
  setSelectedView = (selectedView, backgroundColor) => {
    this.setState({selectedView, backgroundColor: backgroundColor})
  }
  render() {  
    const { loaded, selectedView } = this.state

    if(selectedView === 'Log Out'){
      return <Logout {...this.state}/>
    }
     
    return (
      <div>
      <Header {...this.state} setSelectedView={this.setSelectedView}/>
      {!loaded ? <ProgressBar /> : <Home {...this.state} refreshVideos={this.refreshVideos}/>}
     {/* <Footer ref={(ref) => {this.footer = ref}} />*/}
      </div>
    );
  }
  
}


export default withRouter(MainView);
//export default withAuthenticator(App, true);
